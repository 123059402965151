<template>
  <div>
    <router-view />
  </div>
</template>
<script>
const CryptoJS = require("crypto-js");

export default {
  data() {
    return {
      isTabClosed: false,
      logoutTimer: null, // Timer for automatic logout
      inactivityDuration: 10 * 60 * 1000, // 10 minutes in milliseconds
    };
  },
  methods: {
    logout() {
      this.logOut();
      location.replace("/");
    },
    encryptIt(string) {
      let encrypted = CryptoJS.AES.encrypt(
        `${string}`,
        "secret key"
      ).toString();
      return encrypted;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");

.sr_no {
  border-top-left-radius: 4px !important;
}

.action_th {
  min-width: 115px;
}

.striped-table .table-row-even {
  background-color: #f5f5f5;
}

.striped-table .table-row-odd {
  background-color: #ffffff;
}

.striped-table .table-row-last {
  border-end-end-radius: 4px !important;
  /* border-radius-bottomleft: 4px !important; */
}
</style>
