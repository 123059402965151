<template>
  <div id="app">
    <v-app>
      <nav class="my_nav d-none d-md-block">
        <v-container :fluid="isPcView === false"
          class="nav-container fill-height d-flex justify-space-between align-center">
          <v-col cols="3" class="logo"><a href="https://mnsadik-v3.netlify.app" target="_blank_"><img width="250"
                src="../../assets/logo.png" alt="" /></a></v-col>
          <v-col cols="7">
            <a class="link nav-heading mr-8 text-decoration-none black--text" href="items.url">
              All Products
            </a>
            <a class="link nav-heading mr-8 text-decoration-none black--text" href="items.url">
              Home Decor
            </a>
            <a class="link nav-heading mr-8 text-decoration-none black--text" href="items.url">
              Garden Decor
            </a>
            <a class="link nav-heading mr-8 text-decoration-none black--text" href="items.url">
              About
            </a>
            <a class="link nav-heading mr-8 text-decoration-none black--text" href="items.url">
              Contact
            </a>
          </v-col>
          <v-col align="center">
            <v-icon size="35" style="border: 1px solid;" color="black">mdi-cart</v-icon>
            <v-icon size="35" color="black">mdi-account</v-icon>
          </v-col>
        </v-container>
      </nav>
      <div>
        <router-view />
      </div>
    </v-app>
  </div>
</template>

<script type="text/javascript">
import { mapActions } from "vuex";

export default {
  data() {
    return {
      drawer: true,
      isPcView: false,
    };
  },
  mounted() {
    if (window.innerWidth >= 1600) {
      this.isPcView = true
    }
  },
  components: {},
  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      this.logOut();
    },
  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Rose:wght@300..700&family=Sora:wght@100..800&display=swap');

* {
  font-family: "Sora", sans-serif;
}

.nav-heading {
  font-family: "Red Rose", serif !important;
  font-weight: 500;
}

.text-h4.heading {
  font-family: "Red Rose", serif !important;
}

.text-h3.heading {
  font-family: "Red Rose", serif !important;
}

.heading {
  font-family: "Red Rose", serif !important;
  font-weight: 700;
}
</style>
<style scoped>
#app {
  /*background: url(https://images.unsplash.com/photo-1551970634-747846a548cb?ixlib)
    center/cover no-repeat;*/
  overflow: hidden;
  z-index: 1;
}

@media(min-width: 1400px) {
  .nav-container {
    padding-left: 100px;
    padding-right: 100px;
  }
}
</style>