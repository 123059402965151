<template>
  <v-container :fluid="isPcView === false">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="5">
            <v-card width="550" height="435" class="home-card py-8 float-end" style="background: #dca54a;">
              <v-card-title class=" text-h3 white--text" style="line-height: 1.4;">
                <span> Crafted With Care</span>
                <br>
                <span> For Memorable </span>
                <br>
                <span> Moments.</span>
              </v-card-title>
              <v-card-text>
                <v-col cols="12" class="px-0 white--text">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown pri
                </v-col>
                <v-col cols="12" class="px-0">
                  <v-btn color="white" class="px-10 py-5" elevation="0">shop now</v-btn>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <img src="../../assets/garden.png" :width="$vuetify.breakpoint.lgAndDown ? 550 : 700" height="500" />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="mt-16 pt-16" cols="12">
        <v-row>
          <v-col cols="6">
            <div style="position: relative;" class="float-end">
              <img src="../../assets/cake.png" :width="$vuetify.breakpoint.lgAndDown ? 530 : 630" height="340" />
              <span style="position: absolute; left: 12px; top: 8px;" class="text-h6"> Explore Cake <v-icon size="35"
                  color="black">mdi-arrow-right-thin</v-icon></span>
            </div>
          </v-col>
          <v-col cols="6">
            <div style="position: relative;">
              <img src="../../assets/flower.jpeg" :width="$vuetify.breakpoint.lgAndDown ? 530 : 550" height="340" />
              <span style="position: absolute; left: 12px; top: 8px;" class="text-h6"> Explore Flower <v-icon size="35"
                  color="black">mdi-arrow-right-thin</v-icon></span>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="mt-16 pt-16 pb-5" cols="12" align="center">
        <span class="text-h3 heading">
          New Arrival
        </span>
      </v-col>
      <v-col cols="12">
        <v-container class="new-arrival-class">
          <v-row class="justify-center">
            <v-col cols="3" xl="2" lg="3" v-for="(item, index) in products" :key="index">
              <v-col cols="12" class="py-0">
                <img src="../../assets/cake.png" width="210" height="300" />
              </v-col>
              <v-row class="mx-0 align-center" style="padding-top:6px;">
                <v-col cols="10" class="py-0 px-0">
                  <v-col cols="12" class="py-0">
                    <span class="text-subtitle-1 font-weight-bold"> {{ item.name }} </span>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <span class="text-subtitle-1"> {{ item.price }} </span>
                  </v-col>
                </v-col>
                <v-col cols="2" class="py-0 px-0">
                  <v-icon v-if="!item.inCart" class="py-1 px-1" color="black" style="border: 1px solid;"
                    @click="addToCart(index)">
                    mdi-cart-outline
                  </v-icon>
                  <v-icon v-else class="py-1 px-1" color="black" style="border: 1px solid;">
                    mdi-cart-check
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" class="mt-16 pt-16 pb-0 mb-0" style="background: #faf5e5;">
        <v-container>
          <v-row>
            <v-col cols="6" align="center">
              <img src="../../assets/flower-pot.png" height="400" width="370" />
            </v-col>
            <v-col cols="6">
              <v-col cols="12" class="">
                <v-col cols="8" class="text-start px-0">
                  <span class="text-h4 heading font-weight-bold">
                    Unique Designs
                  </span>
                </v-col>
                <v-col cols="8" class="px-0">
                  <span class="text-subtitle-2 text-start float-end">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown pri
                  </span>
                </v-col>
              </v-col>
            </v-col>
            <v-col cols="6" align="center">
              <v-col cols="12" class="">
                <v-col cols="8" class="text-start px-0">
                  <span class="text-h4 font-weight-bold heading">
                    High-End Quality
                  </span>
                </v-col>
                <v-col cols="8" class="px-0">
                  <span class="text-subtitle-2 text-start float-end">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown pri
                  </span>
                </v-col>
              </v-col>
            </v-col>
            <v-col cols="5">
              <img src="../../assets/flower-pot.png" height="400" width="370" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" style="background: #faf5e5;">
        <v-row>
          <v-col cols="12" align="center" class="mt-16 pt-16">
            <v-col cols="12" class="py-0">
              <v-icon size="40" color="black">
                mdi-instagram
              </v-icon>
            </v-col>
            <v-col cols="12" class="py-0">
              <span class="text-h4 heading font-weight-bold">
                Follow @antiques
              </span>
            </v-col>
            <v-col cols="12" class="py-0">
              <span class="text-body-2 text-start">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              </span>
            </v-col>
          </v-col>
          <v-col cols="12" class="px-0">
            <v-row>
              <v-col cols="3" v-for="n in 4" :key="n">
                <img src="../../assets/flower-pot.png" height="400" width="330" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" style="background: #e1e0df;">
        <v-container>
          <v-row>
            <v-col cols="12" class="pt-13" align="center">
              <v-row align="center">
                <v-col cols="3" align="start" class="px-0">
                  <v-card width="250" height="160">
                    <v-col cols="12" class="py-2">
                      <v-icon size="50" color="#dca54a">mdi-truck</v-icon>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <span class="heading">
                        Free Shiping
                      </span>
                    </v-col>
                    <v-col cols="12" class="py-0" style="line-height: 0;">
                      <span class="text-caption">
                        Lorem Ipsum is simply dummy text of the printing.
                      </span>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="3" align="start" class="px-0">
                  <v-card width="250" height="160">
                    <v-col cols="12" class="py-2">
                      <v-icon size="50" color="#dca54a">mdi-artstation</v-icon>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <span class="heading">
                        30 Days Return
                      </span>
                    </v-col>
                    <v-col cols="12" class="py-0" style="line-height: 0;">
                      <span class="text-caption">
                        Lorem Ipsum is simply dummy text of the printing.
                      </span>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="3" align="start" class="px-0">
                  <v-card width="250" height="160">
                    <v-col cols="12" class="py-2">
                      <v-icon size="50" color="#dca54a">mdi-percent</v-icon>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <span class="heading">
                        Best Offers
                      </span>
                    </v-col>
                    <v-col cols="12" class="py-0" style="line-height: 0;">
                      <span class="text-caption">
                        Lorem Ipsum is simply dummy text of the printing.
                      </span>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="3" align="start" class="px-0">
                  <v-card width="250" height="160">
                    <v-col cols="12" class="py-2">
                      <v-icon size="50" color="#dca54a">mdi-lock</v-icon>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <span class="heading">
                        Best Offers
                      </span>
                    </v-col>
                    <v-col cols="12" class="py-0" style="line-height: 0;">
                      <span class="text-caption">
                        Lorem Ipsum is simply dummy text of the printing.
                      </span>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <!-- <v-col cols="12" style="height: 400px;">
        <video style="width: 100%;" height="400" controls>
          <source src="../../assets/example-video.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
// import { Carousel, Slide as VueCarouselSlide } from "vue-carousel";
// import axios from "axios";
export default {
  name: "LAndingPAge",
  data() {
    return {
      isPcView: false,
      products: [
        { name: 'Chocolate Cake', price: '$18.00', inCart: false },
        { name: 'Chocolate Cake', price: '$18.00', inCart: false },
        { name: 'Chocolate Cake', price: '$18.00', inCart: false },
        { name: 'Chocolate Cake', price: '$18.00', inCart: false },
        { name: 'Chocolate Cake', price: '$18.00', inCart: false },
      ],
      InCart: false,
    };
  },
  // components: {
  //   Carousel,
  //   VueCarouselSlide,
  // },
  computed: {

  },
  mounted() {
    if (window.innerWidth >= 1600) {
      this.isPcView = true
    }
    // this.getmasters();
    // this.getData();
    // this.isMounted = true;
  },
  methods: {
    addToCart(index) {
      this.products[index].inCart = !this.products[index].inCart;
    }
  }
};
</script>
<style scoped>
@media(min-width: 1400px) {
  .home-card {
    margin-right: -122px;
    margin-top: 33px;
  }

  .new-arrival-class .col-xl-2 {
    max-width: 13.666667% !important;
  }
}

@media(min-width: 1080px) and (max-width: 1399px) {
  .new-arrival-class .col-lg-3 {
    max-width: 19% !important;
  }

  .home-card {
    margin-right: -70px;
    margin-top: 33px;
  }
}
</style>
