import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

// Layout
import UnAuthLayout from "../views/layouts/unauth-layout.vue";
// import AuthLayout from "../views/layouts/auth-layout.vue";

// Static
import HomePage from "../views/home/index.vue";

// Auth
import LoginPage from "../views/auth/login.vue";
import ForgotPasswordPage from "../views/auth/forgot-password.vue";

const routes = [
  // AuthLayout
  // {
  //   path: "/auth",
  //   name: "AuthLayout",
  //   component: AuthLayout,
  //   children: [
  //     {
  //       path: "/home",
  //       name: "HomePage",
  //       component: HomePage,
  //     },
  //   ],
  // },
  // End AuthLayout

  // UnAuthLayout
  {
    path: "/unauth",
    name: "UnAuthLayout",
    component: UnAuthLayout,
    children: [
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
      },
      {
        path: "/forgot-password",
        name: "ForgotPasswordPage",
        component: ForgotPasswordPage,
      },
      {
        path: "/home",
        name: "HomePage",
        component: HomePage,
      },
    ],

  },
  //End UnAuthLayout
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === null) {
    console.log("5");
    return next({
      name: "HomePage",
    });
  }
  next();
});

export default router;
